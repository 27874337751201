import { Reducer } from "redux";
import { KnownAction } from "./actionTypes";

export type PropPhotoViewerTabType = "photos" | "streetView" | "map";

export interface PropPhotoViewerUIState {
  activeTab: PropPhotoViewerTabType;
  activeMobileImg: string;
  activeSlideIndex: number;
}

export const initialPropPhotoViewerUIState: PropPhotoViewerUIState = {
  activeTab: "photos",
  activeMobileImg: "",
  activeSlideIndex: 0,
};

export const PropPhotoViewerUIReducer: Reducer<PropPhotoViewerUIState, KnownAction> = (
  state = initialPropPhotoViewerUIState,
  action: KnownAction,
) => {
  switch (action.type) {
    case "SET_ACTIVE_TAB":
      return { ...state, activeTab: action.payload };
    case "SET_ACTIVE_MOBILE_IMG":
      return { ...state, activeMobileImg: action.payload };
    case "SET_ACTIVE_SLIDE_INDEX":
      return { ...state, activeSlideIndex: action.payload };
    case "RESET_PROP_PHOTO_VIEWER_UI":
      return { ...initialPropPhotoViewerUIState };
    default:
      return state || initialPropPhotoViewerUIState;
  }
};
