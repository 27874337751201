import * as React from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { OrderRole } from "../dataTypes/orders/enums/orderRole";
import { startCase } from "lodash";
import { OrderType, OrderSubType } from "../dataTypes/orders/enums/orderType";
import { OrderServiceType } from "../dataTypes/orders/enums/orderServiceType";
import { OrderProductType, OrderProductSubType } from "../dataTypes/orders/enums/orderProductType";
import { OrderRepresentation } from "../dataTypes/orders/enums/orderRepresentation";
import { OrderContactType } from "../dataTypes/orders/enums/orderContactType";
import { AsYouType } from "libphonenumber-js";
import { PropertyTransactionConsumer } from "../dataTypes/properties/propertyDetail/ownership/propertyTransactionConsumer";
import { UnknownValue } from "../components/titlefyPro/propertyDetails/propertyInformations/listUI";

dayjs.extend(utc);

export function currencyShort(input: number): string {
  const options: Intl.NumberFormatOptions = {
    notation: "compact",
    style: "currency",
    currency: "USD", //trailingZeroDisplay: "stripIfInteger"
  };
  if (input >= 1e6) {
    options.minimumFractionDigits = 2;
  }
  const numFormatter = Intl.NumberFormat("en", options);
  return numFormatter.format(input);
}

export function formatCurrency(input: number | null | undefined, decimalPlaces?: number): string {
  if (input === null || input === undefined) {
    return "";
  }

  let decimals: number = 0;

  if (decimalPlaces) decimals = decimalPlaces;

  return input.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: decimals,
  });
}

export function formatCurrencyGrid(input: number): string {
  let formattedCurrency: string = "";

  const value = formatCurrency(input);
  const splitValue = value.split(",");

  if (input >= 1000000) {
    formattedCurrency = `${splitValue[0]}.${splitValue[1].slice(0, 2)}M`;
  } else {
    formattedCurrency = `${splitValue[0]}K`;
  }

  return formattedCurrency;
}

function trimZeros3(str: string) {
  if (str === null || str === undefined) return "";
  let string = str;
  while (string.endsWith("0")) {
    string = string.slice(0, string.length - 1);
  }
  return string;
}
//-=---------------------

export function formatPhone(phone: string): string {
  if (phone === null || phone === undefined) return "";

  const formattedPhone: string = new AsYouType("US").input(phone);

  return formattedPhone;
}

export function formatFireplace(fireplace: string): string {
  let result = "";

  if (isNaN(parseInt(fireplace))) {
    if (fireplace.toUpperCase() === "Y") result = "Yes";
    if (fireplace.toUpperCase() === "N") result = "No";
  } else {
    result = fireplace;
  }

  return result;
}

export function formatLienName(lien: any, borrower: any, index: number): string {
  let name = "";

  // use the full name if it exists, if not combine the first/last names
  if (borrower && borrower.name) {
    if (borrower.name.fullName) {
      name = borrower.name.fullName;
    } else if (borrower.name.firstName && borrower.name.lastName) {
      name = borrower.name.firstName + " " + borrower.name.lastName;
    }
  }

  // if there's more than one borrower and not on the last borrower, add a comma for separating the names
  if (lien.borrowers.length > 1 && index + 1 !== lien.borrowers.length) {
    name = name + ",";
  }

  return name;
}

export function getPropTransactionConsumerName(consumer: PropertyTransactionConsumer): string {
  // try fullName first
  if (consumer?.name?.fullName) return consumer?.name?.fullName;
  // otherwise try first/last
  if (consumer?.name?.firstName && consumer?.name?.lastName)
    return `${consumer.name.firstName} ${consumer.name.lastName}`;
  return "";
}

export function disclosureStatusUnknownValue(disclosureStatus: boolean): string {
  return disclosureStatus ? "Undisclosed" : UnknownValue;
}

export function formatOwnerNameMapOption(ownerLastName: string): string {
  if (ownerLastName === "") return "-";
  return ownerLastName.length > 9 ? `${ownerLastName.slice(0, 9)}...` : ownerLastName;
}

export function formatTimeLocal(input: string): string {
  if (!input) return "-";
  const date = new Date(input);
  return date.toLocaleTimeString();
}

export function formatDate(input: any): string {
  if (!input) {
    return UnknownValue;
  }
  let date = input.split("T").slice(0, 1).join();
  return dayjs(date).utc().local().format("MM/DD/YYYY");
}

export function formatDateLocal(date: any, format: string | undefined = "MM/DD/YYYY"): string {
  return dayjs(date).format(format);
}

export function startOfDay(date: Date) {
  return dayjs().startOf("day").toISOString();
}

export function formatDateSimple(date: Date): string {
  if (!date) return "Unknown";
  return date.getUTCMonth() + 1 + "/" + date.getUTCDate() + "/" + date.getUTCFullYear();
}

export function getMonthNameShort(date: string): string {
  const dateObj = new Date(date);
  const month = dateObj.toLocaleString("en-US", { month: "short" });
  return month;
}

export function formatDateMonthNameDayNum(date: string) {
  return `${getMonthNameShort(date)} ${new Date(date).getUTCDate()}`;
}

// for some reason typescript doesn't like passing a "Date" type to the Date() constructor...even though JS allows this just fine...
export function addDaysToDate(date: any, days: number): Date {
  const copyDate = new Date(date);
  copyDate.setDate(date.getDate() + days);
  return copyDate;
}

export function dateDiffInYears(input: string): string {
  if (!input) {
    return "Unknown";
  }
  let startdate: any, diffdate: any;
  startdate = new Date(input);
  diffdate = new Date(+new Date() - startdate);
  if (diffdate.toISOString().slice(0, 4) - 1970 === 0) {
    return diffdate.getMonth() + 1 + " mos ago";
  } else if (diffdate.getMonth() + 1 === 12) {
    return diffdate.toISOString().slice(0, 4) - 1970 + 1 + " yrs ago ";
  } else {
    return diffdate.toISOString().slice(0, 4) - 1970 + " yrs, " + (diffdate.getMonth() + 1) + " mos ago ";
  }
}

export function propAgeInYears(yearBuilt: string): number | null {
  if (!yearBuilt) return null;

  const yearBuiltDate = new Date(yearBuilt);
  const today = new Date();

  return today.getUTCFullYear() - yearBuiltDate.getUTCFullYear();
}

export function startOfYear() {
  return dayjs().startOf("year").toISOString();
}

export function getFirstOfMonth(date: Date): string {
  return dayjs().startOf("month").toISOString();
}

export function getFirstOfPreviousMonth(): string {
  const today = new Date();
  const firstOfPrevMonth: string = new Date(today.getUTCFullYear(), today.getUTCMonth() - 1, 1).toLocaleDateString();
  return firstOfPrevMonth;
}

export function getDateFromXdays(numOfDays: number): string {
  return dayjs().subtract(numOfDays, "day").toISOString();
}

export function dateDiffInDays(startDate: Date, endDate: Date): number {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;

  // using UTC takes care of issues around JS date objects daylights saving time
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
  const utc2 = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

  //return Math.floor((utc2 - utc1) / _MS_PER_DAY);

  const diffDays = Math.round(Math.abs((utc2 - utc1) / _MS_PER_DAY));
  return diffDays;
}

export function isLeapYear(year: number): boolean {
  return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
}

export function formatForGrid(input: any, emptyIndicator?: string) {
  let output: string = emptyIndicator ? emptyIndicator : "\u00A0";

  if (input !== null && input !== undefined && input !== "") {
    output = input.toString();
  }

  return output;
}

export function formatYesNo(input: any): string {
  if (input === undefined || input === null) return UnknownValue;
  return input ? "Yes" : "No";
}

export function formatOrdinal(input: number) {
  var s = ["th", "st", "nd", "rd"],
    v = input % 100;
  return input + (s[(v - 20) % 10] || s[v] || s[0]);
}

export function uniqueID(prefix?: string) {
  return `${prefix || ""}_${Math.random().toString(36).substr(2, 9)}`;
}

export function formatRole(roleId: OrderRole): string {
  if (roleId === OrderRole.BuyersAgent) return "Buyer's Agent";
  if (roleId === OrderRole.SellersAgent) return "Seller's Agent";
  return startCase(OrderRole[roleId]);
}

export function formatOrderType(order: OrderType) {
  return startCase(OrderType[order]);
}

export function formatOrderSubType(orderSubType: OrderSubType): string {
  return startCase(OrderSubType[orderSubType]);
}

export function formatServiceType(service: OrderServiceType) {
  if (service === OrderServiceType.EscrowTitle) return "Escrow with Title";
  return startCase(OrderServiceType[service]);
}

export function formatOrderProductType(productType: OrderProductType) {
  return startCase(OrderProductType[productType]);
}

// Lodash's startCase strips out special chars, this doesn't
export function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function formatOrderRepresentation(rep: OrderRepresentation) {
  if (rep === OrderRepresentation.NotApplicable) return "Not Applicable";
  if (rep === OrderRepresentation.RepresentingSeller) return "Seller";
  if (rep === OrderRepresentation.RepresentingBuyer) return "Buyer";
  if (rep === OrderRepresentation.RepresentingBoth) return "Both";
}

export function formatOrderContactType(contactType: OrderContactType) {
  return OrderContactType[contactType];
}

export function formatBytes(bytes: number, decimals: number = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function trimAllWhitespace(string: string): string {
  return string.replace(/\s+/g, "");
}
