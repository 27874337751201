import { OrderRole } from "../enums/orderRole";
import { OrderContactType } from "../enums/orderContactType";
import { OrderRepresentation } from "../enums/orderRepresentation";
import { AddressOrderSub, emptyAddressFormInfo } from "../../properties";
import { ContactCommunicationPref } from "../enums/contactCommunicationPref";
import { PropertyType } from "../../properties/propertyType";
import { OrderProductType, OrderProductSubType } from "../enums/orderProductType";
import { OrderServiceType } from "../enums/orderServiceType";
import { OrderType, OrderSubType } from "../enums/orderType";
import {
  OrderDetails,
  Payoff,
  InsuranceInfo,
  HomeWarrantyInfo,
  PropertyDisclosureInfo,
  PestControlInfo,
  HomeOwnersAssociationInfo,
  Loan,
  BaseCompany,
} from "./order";
import { UserGroup } from "dataTypes/user/user";
import { uniqueID } from "utilities/formatters";
import { OrderProductCategory } from "../enums/orderProductCategory";

export const initialOrderSubmission: OrderSubmission = {
  orderType: null,
  orderSubType: null,
  services: null,
  commercial: false,
  construction: false,
  contacts: null,
  orderDetails: null,
  properties: null,
  loans: null,
  payoffs: null,
  insuranceInfo: null,
  homeWarrantyInfo: null,
  propertyDisclosureInfo: null,
  pestControlInfo: null,
  homeOwnersAssociationInfo: null,
  specialInstructions: "",
  additionalOrderOptions: [],
};

export interface OrderSubmission {
  additionalOrderOptions: number[] | null;
  commercial: boolean;
  construction: boolean;
  contacts: OrderSubmissionContact[] | null;
  homeOwnersAssociationInfo: HomeOwnersAssociationInfo | null;
  homeWarrantyInfo: HomeWarrantyInfo | null;
  insuranceInfo: InsuranceInfo | null;
  loans: Loan[] | null;
  orderDetails: OrderDetails | null;
  orderId?: number;
  orderSubType: OrderSubType | null;
  orderType: OrderType | null;
  payoffs: Payoff[] | null;
  pestControlInfo: PestControlInfo | null;
  properties: OrderSubmissionProperty[] | null;
  propertyDisclosureInfo: PropertyDisclosureInfo | null;
  services: OrderSubmissionService[] | null;
  specialInstructions: string | null;
}

export interface OrderSubmissionService {
  serviceType: OrderServiceType;
  products?: OrderSubmissionProduct[];
}

export interface OrderSubmissionProduct {
  // product category only used in order contact options request
  productCategory?: OrderProductCategory;
  productType: OrderProductType;
  productSubType: OrderProductSubType;
}

export interface OrderSubmissionProperty {
  propertyId: number;
  fipsCode: string;
  countyName: string;
  apn: string;
  latitude: number;
  longitude: number;
  address: AddressOrderSub;
  ownerName: string;
  propertyType: PropertyType;
  legalDescription: string;
  ownerOccupied: boolean;
}

export interface OrderSubmissionContact {
  address: AddressOrderSub;
  businessSourceRoles?: OrderRole[];
  communicationPreference?: ContactCommunicationPref;
  companyName: string;
  // optional field used to confirm contact data
  // gets removed before order submit
  confirmed?: boolean;
  contactType?: OrderContactType;
  email: string;
  firstName: string;
  forwardingAddress?: AddressOrderSub;
  groups?: UserGroup[];
  isBusinessSource: boolean;
  //Joint group to be used to connect sellers and borrowers into the joint entity
  jointGroup: number;
  lastName: string;
  middleName: string;
  orderRepresentation: OrderRepresentation;
  phone: string;
  photoUrl: string;
  publicRecordContactType: OrderContactType;
  publicRecordFirstName: string;
  publicRecordLastName: string;
  publicRecordMiddleName: string;
  publicRecordSuffix: string;
  role?: OrderRole;
  suffix: string;
  taxId: string;
  title?: string;
  userId?: number | string;
}

export const emptyOrderSubContact = (role?: OrderRole, contactType?: OrderContactType): OrderSubmissionContact => ({
  address: {
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    streetName: "",
    streetNumber: 0,
    fullAddress: "",
    streetAddress: "",
  },
  companyName: "",
  contactType: contactType || OrderContactType.Individual,
  email: "",
  firstName: "",
  isBusinessSource: false,
  jointGroup: 0,
  lastName: "",
  middleName: "",
  orderRepresentation: OrderRepresentation.NotApplicable,
  phone: "",
  photoUrl: "",
  publicRecordContactType: OrderContactType.Individual,
  publicRecordFirstName: "",
  publicRecordLastName: "",
  publicRecordMiddleName: "",
  publicRecordSuffix: "",
  role: role,
  suffix: "",
  taxId: "",
  userId: "",
});

export interface OrderSubmissionResult {
  orderId: number;
  serviceSubmissionResults: OrderServiceSubmissionResult[];
}

export interface OrderServiceSubmissionResult {
  succeeded: boolean;
  orderNumber: string;
  orderProviderId: number;
  productionSystemOrderId: string;
  serviceType: number;
  failReasons: OrderFailReason[];
}

export interface OrderFailReason {
  failCode: number;
  failDescription: string;
}

export enum OrderSubmissionFailReason {
  MissingOrderType = 1,
  MissingBusinessSource = 2,
  ProviderError = 100,
}
