import { hasUserGroup, User, UserGroup } from "dataTypes/user/user";
import { OrderSubmission, OrderSubmissionService } from "../interfaces/orderSubmission";
import { OrderType } from "./orderType";

/** When adding new members, plz update the `serviceTypeLabels` map below, as well as `DecisionTypesMapper` in `order.ts`.
 * If it's a new Decision service, add it to `DecisionServiceTypes` below.
 * If it's a TCR related variant, plz add to `tcrVariants` list below.
 * Lastly, be mindful to update `DecisionTypesMapper` for mapping to associated `DecisionProductType`
 * */
export enum OrderServiceType {
  Undefined = 0,
  Title = 1,
  Escrow = 2,
  EscrowTitle = 3,

  // NOTE - any new decision services should be added to DecisionServiceTypes list below
  TitleCheck = 4,
  TitleDecision = 5,
  DecisionRealEstatePro = 6,
  DecisionServicer = 7,
  // FlueidComplete is 8, but we do not show it on the web
  DecisionTitleConditionReport = 9,
  PreliminaryTitleConditionReport = 10,
}

export const serviceTypeLabels = new Map<OrderServiceType, string>([
  [OrderServiceType.Title, "Title"],
  [OrderServiceType.Escrow, "Escrow"],
  [OrderServiceType.EscrowTitle, "Escrow and Title"],
  [OrderServiceType.TitleCheck, "TitleCheck"],
  [OrderServiceType.TitleDecision, "TitleDecision"],
  [OrderServiceType.DecisionRealEstatePro, "Flueid Decision for Real Estate Pro"],
  [OrderServiceType.DecisionServicer, "Flueid Decision for Servicer"],
  [OrderServiceType.DecisionTitleConditionReport, "Title Condition Report"],
  [OrderServiceType.PreliminaryTitleConditionReport, "Preliminary Title Condition Report"],
]);

/** List of all TitleCheck aka "pre-close" service types */
export const DecisionServiceTypes = [
  OrderServiceType.TitleCheck,
  OrderServiceType.TitleDecision,
  OrderServiceType.DecisionRealEstatePro,
  OrderServiceType.DecisionServicer,
  OrderServiceType.DecisionTitleConditionReport,
  OrderServiceType.PreliminaryTitleConditionReport,
];

/** List of TCR related service types. */
export const tcrVariants = [
  OrderServiceType.DecisionTitleConditionReport,
  OrderServiceType.PreliminaryTitleConditionReport,
];

/** Determines is service is a variant of TCR. Used for conditional rendering of content on order details page. */
export const isTcrVariant = (serviceType?: OrderServiceType) => {
  return tcrVariants.includes(serviceType!);
};

/** List of all TitleCheck service types that are for external business source users, such as Lenders & Real Estate Pros. Mainly it just excludes Decision Title */
export const DecisionForBizSrcTypes = DecisionServiceTypes.filter((x) => x !== OrderServiceType.TitleDecision);

export const isDecisionService = (service?: OrderServiceType | null) =>
  DecisionServiceTypes.includes(service as OrderServiceType);

export const isDecisionForBizSrc = (service?: OrderServiceType | null) =>
  DecisionForBizSrcTypes.includes(service as OrderServiceType);

export const isDecisionOrder = (services: OrderSubmissionService[] | null) =>
  !!services?.every((x) => isDecisionService(x.serviceType));

/** Determines if provided service type is the only type in a list of services */
export const hasOnlyServiceType = <T extends { serviceType: OrderServiceType }>(
  serviceType: OrderServiceType,
  services?: T[] | null,
) => {
  return services?.length === 1 && services.every((x) => x.serviceType === serviceType);
};

/** Determines if provided service type exists in list of services */
export const hasServiceType = <T extends { serviceType: OrderServiceType }>(
  serviceType: OrderServiceType,
  services?: T[] | null,
) => {
  return services?.some((x) => x.serviceType === serviceType);
};

/** Accepts any list of objects that have a serviceType: OrderServiceType field and returns all closing services */
export const getClosingServices = <T extends { serviceType: OrderServiceType }>(list?: T[]) =>
  list?.filter((x) => !isDecisionService(x.serviceType));

/** Accepts any list of objects that have a serviceType: OrderServiceType field and returns all preclosing services */
export const getPreclosingServices = <T extends { serviceType: OrderServiceType }>(list?: T[]) =>
  list?.filter((x) => isDecisionService(x.serviceType));

/** Main OrderServiceTypes (Title, Escrow, EscrowTitle) that are available to most OrderTypes */
export const baseServices = [OrderServiceType.Title, OrderServiceType.Escrow, OrderServiceType.EscrowTitle] as const;

/** Map that specifies which service types are available to an order type */
export const serviceTypesMap = new Map<OrderType, OrderServiceType[]>([
  [OrderType.Refinance, [...baseServices, OrderServiceType.TitleCheck, OrderServiceType.TitleDecision]],
  [
    OrderType.Purchase,
    [
      ...baseServices,
      OrderServiceType.TitleCheck,
      OrderServiceType.TitleDecision,
      OrderServiceType.PreliminaryTitleConditionReport,
    ],
  ],
  [OrderType.REO, [...baseServices]],
  [OrderType.HomeEquity, [...baseServices, OrderServiceType.TitleCheck, OrderServiceType.TitleDecision]],
  [OrderType.Default, [...baseServices, OrderServiceType.TitleCheck, OrderServiceType.TitleDecision]],
  [OrderType.Uninsured, [OrderServiceType.Title]],
  [OrderType.Presale, [...baseServices]],
]);

export const showFdDisclaimer = (service?: OrderServiceType) => {
  // it's a decision type & has a disclaimer map field
  return !!(service && isDecisionService(service) && fdDisclaimerMap.get(service));
};

export const fdDisclaimerMap = new Map<OrderServiceType | undefined, string>([
  [
    OrderServiceType.TitleCheck,
    "As a condition to downloading the TitleCheck report, the user hereby acknowledges that a formal loan application has been completed and signed by the borrower(s) prior to the submission of their request and agrees the use of such report is governed by the underlying terms and conditions set forth in the user's TitleCheck license agreement.",
  ],
  [
    OrderServiceType.TitleDecision,
    "As a condition to downloading the TitleDecision report, the user hereby acknowledges that their request is submitted pursuant to the underwriting of title insurance and agrees the use of such report is governed by the underlying terms and conditions set forth in the user’s TitleCheck license agreement.",
  ],
  [
    OrderServiceType.DecisionRealEstatePro,
    "As a condition to downloading the TitleCheck for Real Estate Pros report, the user hereby acknowledges that a formal listing agreement has been executed prior to the submission of their request and agrees the use of such report is governed by the underlying terms and conditions set forth in the user’s TitleCheck license agreement.",
  ],
  [
    OrderServiceType.PreliminaryTitleConditionReport,
    "As a condition to downloading the Preliminary Title Condition Report, the user hereby acknowledges that a formal listing agreement has been executed prior to the submission of their request and agrees the use of such report is governed by the underlying terms and conditions set forth in the user’s TitleCheck license agreement.",
  ],
]);
