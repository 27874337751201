import { Reducer, combineReducers } from "redux";
import { OrderRole } from "../../../dataTypes/orders/enums/orderRole";
import { OrderStep, NewOrderView } from "../../../dataTypes/orders/enums/orderStep";
import { initialCompletedSteps, OrderSteps } from "../../../dataTypes/orders/enums/orderSteps";
import {
  initialOrderSubmission,
  OrderSubmission,
  OrderSubmissionContact,
} from "../../../dataTypes/orders/interfaces/orderSubmission";
import { PropertyDetailLite } from "../../../dataTypes/properties/propertyDetailLite";
import { UserLite } from "../../../dataTypes/user/userLite";
import { KnownAction } from "./actionTypes";
import { PropertyType } from "../../../dataTypes/properties/propertyType";

export interface OrderPayoffsReview {
  openLiensFound: boolean;
  reviewed: boolean;
}

interface AdditionalInfo {
  ownerOcc: boolean;
  orderPropDetailLite: PropertyDetailLite | null;
  bizSrcUser: UserLite | null;
  payoffsReview: OrderPayoffsReview;
  isfreeFormProperty: boolean;
  originalPropType: string;
}

export interface OrderSubmissionState {
  orderSubmission: OrderSubmission;
  additionalInfo: AdditionalInfo;
  orderSteps: OrderSteps;
}

const initialState: OrderSubmissionState = {
  orderSubmission: initialOrderSubmission,
  additionalInfo: {
    ownerOcc: false,
    orderPropDetailLite: null,
    bizSrcUser: null,
    payoffsReview: { openLiensFound: false, reviewed: false },
    isfreeFormProperty: false,
    originalPropType: "",
  },
  orderSteps: {
    newOrderView: NewOrderView.StepsOverview,
    activeStep: OrderStep.PropertyAddress,
    completedSteps: initialCompletedSteps,
    confirmChangesOpen: false,
  },
};

export const OrderSubmissionReducer = combineReducers<OrderSubmissionState, KnownAction>({
  orderSubmission: (state: OrderSubmission = initialState.orderSubmission, action: KnownAction) => {
    switch (action.type) {
      case "SET_ORDER_TYPE":
        return { ...state, orderType: action.payload };
      case "SET_ORDER_SUB_TYPE":
        return { ...state, orderSubType: action.payload };
      case "SET_ORDER_SERVICES":
        return { ...state, services: action.payload };
      case "SET_BIZ_SRC_CONTACT":
        let contacts: OrderSubmissionContact[] = [];
        // if contacts already exist, need to filter out the existing bizSrcUser and add the new one in
        if (state.contacts) {
          contacts = state.contacts.filter((x: OrderSubmissionContact) => !x.isBusinessSource);
          contacts = [...contacts, action.payload];
        } else {
          // otherwise just add the bizSrcUser to an array
          contacts = [action.payload];
        }
        return { ...state, contacts: contacts };
      case "SET_ORDER_CONTACTS":
        if (action.payload === null) {
          return { ...state, contacts: null };
        } else {
          return { ...state, contacts: [...action.payload] };
        }

      case "SET_ORDER_DETAILS":
        return { ...state, orderDetails: action.payload };
      case "SET_ORDER_PROPERTY":
        return { ...state, properties: action.payload };
      case "SET_ORDER_LOANS":
        return { ...state, loans: action.payload };
      case "SET_ORDER_PAYOFFS":
        return { ...state, payoffs: action.payload };
      case "SET_INSURANCE_INFO":
        return { ...state, insuranceInfo: action.payload };
      case "SET_HOME_WARRANTY_INFO":
        return { ...state, homeWarrantyInfo: action.payload };
      case "SET_PROP_DISCLOSURE_INFO":
        return { ...state, propertyDisclosureInfo: action.payload };
      case "SET_PEST_CONTROL_INFO":
        return { ...state, pestControlInfo: action.payload };
      case "SET_HOA_INFO":
        return { ...state, homeOwnersAssociationInfo: action.payload };
      case "SET_SPECIAL_INSTRUCTIONS":
        return { ...state, specialInstructions: action.payload };
      case "SET_ADDITIONAL_ORDER_OPTIONS":
        return { ...state, additionalOrderOptions: action.payload };
      case "SET_CONSTRUCTION":
        return { ...state, construction: action.payload };
      case "SET_COMMERCIAL_TRANSACTION":
        return { ...state, commercial: action.payload };
      case "SET_ORDER_SUBMISSION":
        return { ...action.payload };
      case "SET_ORDER_ID":
        return { ...state, orderId: action.payload };
      case "RESET_COMMERCIAL_TRANS":
        return { ...state, commercial: false };
      case "RESET_STEPS":
        const updatedState: OrderSubmission = { ...state };

        if (action.payload.indexOf(OrderStep.BusinessSource) > -1) updatedState.contacts = null;

        if (action.payload.indexOf(OrderStep.People) > -1 && action.payload.indexOf(OrderStep.BusinessSource) < 0) {
          let filteredContacts = [];
          if (state.contacts) {
            // want to keep the biz src contact but not anyone else
            filteredContacts = state.contacts.filter(
              (x: OrderSubmissionContact) => x.role === OrderRole.BusinessSource,
            );
            // make sure to reset the biz src's businessSourceRoles
            const updatedContacts: OrderSubmissionContact[] = filteredContacts.map((x: OrderSubmissionContact) => {
              if (x.isBusinessSource) {
                const updatedBizSrc = { ...x };
                updatedBizSrc.businessSourceRoles = [];
                return updatedBizSrc;
              }
              return x;
            });
            updatedState.contacts = updatedContacts;
          }
          //updatedState.contacts = null;
        }

        if (action.payload.indexOf(OrderStep.OrderType) > -1) {
          updatedState.orderType = null;
          updatedState.orderSubType = null;
          updatedState.services = null;
          updatedState.additionalOrderOptions = null;

          // RESET THE CONSTRUCTION
          updatedState.construction = false;

          // RESET COMMERCIAL if propType is not commercial
          if (
            updatedState.properties &&
            updatedState.properties.length &&
            updatedState.properties[0].propertyType === PropertyType.Commercial
          ) {
            updatedState.commercial = true;
          } else {
            updatedState.commercial = false;
          }
        }

        if (action.payload.indexOf(OrderStep.OrderDetails) > -1) {
          updatedState.orderDetails = null;
          updatedState.loans = null;
          updatedState.payoffs = null;
          updatedState.insuranceInfo = null;
          updatedState.homeWarrantyInfo = null;
          updatedState.propertyDisclosureInfo = null;
          updatedState.pestControlInfo = null;
          updatedState.homeOwnersAssociationInfo = null;
          updatedState.specialInstructions = null;
        }

        return { ...updatedState };
      default:
        return state;
    }
  },

  orderSteps: (state = initialState.orderSteps, action: KnownAction) => {
    switch (action.type) {
      case "SET_NEW_ORDER_VIEW":
        return { ...state, newOrderView: action.payload };
      case "SET_STEP_ACTIVE":
        return { ...state, activeStep: action.payload };
      case "UPDATE_COMPLETED_STEPS":
        return { ...state, completedSteps: action.payload };
      case "TOGGLE_CONFIRM_CHANGES":
        return { ...state, confirmChangesOpen: !state.confirmChangesOpen };
      default:
        return state;
    }
  },

  additionalInfo: (state = initialState.additionalInfo, action: KnownAction) => {
    switch (action.type) {
      case "SET_OWNER_OCC":
        return { ...state, ownerOcc: action.payload };
      case "SET_BIZ_SRC_USER":
        return { ...state, bizSrcUser: action.payload };
      case "RESET_BIZ_SRC_USER":
        return { ...state, bizSrcUser: null };
      case "RESET_PAYOFFS_CONFIRMATION":
        return { ...state, payoffsConfirmation: { required: false, confirmed: false } };
      case "GET_ORDER_PROP_DETAIL_LITE":
        return { ...state, orderPropDetailLite: action.payload, ownerOcc: action.payload.ownerOcc };
      case "SET_ORDER_PROP_DETAIL_LITE":
        return {
          ...state,
          orderPropDetailLite: action.payload,
          ownerOcc: action.payload ? action.payload.ownerOcc : false,
        };
      case "SET_ORIGINAL_PROP_TYPE":
        return {
          ...state,
          originalPropType: action.payload,
        };
      case "SET_PAYOFFS_REVIEWABLE":
        return {
          ...state,
          payoffsReview: {
            ...state.payoffsReview,
            openLiensFound: true,
          },
        };
      case "SET_PAYOFFS_REVIEWED":
        return {
          ...state,
          payoffsReview: {
            ...state.payoffsReview,
            reviewed: true,
          },
        };
      case "SET_IS_FREE_FORM_PROPERTY":
        return { ...state, isfreeFormProperty: action.payload };

      default:
        return state;
    }
  },
});

export const OrderSubmissionRootReducer: Reducer<OrderSubmissionState, KnownAction> = (
  state = initialState,
  action: KnownAction,
) => {
  if (action.type === "RESET_ORDER_SUBMISSION_STORE") {
    return initialState;
  }

  return OrderSubmissionReducer(state, action);
};
