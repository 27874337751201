import { hasUserGroup, UserGroup } from "dataTypes/user/user";
import { UserLite } from "dataTypes/user/userLite";
import { enumToDictArray } from "utilities/typescriptHelpers";
import { OrderSubmissionService } from "../interfaces/orderSubmission";
import { isDecisionOrder, hasOnlyServiceType, OrderServiceType } from "./orderServiceType";

export enum OrderRole {
  FinancePro = 1,
  RealEstatePro = 2,
  TitleOfficer = 4,
  EscrowOfficer = 5,
  AccountExecutive = 6,
  TransactionCoordinator = 7,
  FinanceAssistant = 11,
  RealEstateAssistant = 12,
  TitleAssistant = 14,
  EscrowAssistant = 15,
  Borrower = 16,
  Buyer = 17,
  Seller = 18,
  Lender = 19,
  MortgageBroker = 20,
  SellersAgent = 21,
  BuyersAgent = 22,
  IndependentEscrowOfficer = 23,
  IndependentTitleOfficer = 24,
  SellersAttorney = 25,
  BuyersAttorney = 26,
  FldCompleteUser = 29,
  BusinessSource = 50,
  BusinessSourceAssistant = 51,
  TrusteeAttorney = 52,
  Watcher = 99,
}

export const escrowTitleRoles = [OrderRole.EscrowOfficer, OrderRole.TitleOfficer, OrderRole.IndependentEscrowOfficer];

/** Gets list of default order roles for business source. Special cases for different decision services & user groups */
export const getDefaultBizSrcRoles = (
  services: OrderSubmissionService[] | null,
  user?: UserLite | null,
): OrderRole[] => {
  // DecisionRealEstatePro default to OrderRole.RealEstatePro
  if (hasOnlyServiceType(OrderServiceType.DecisionRealEstatePro, services)) {
    return [OrderRole.RealEstatePro];

    // SHORT TERM FIX - DecisionTitle service gets TitleOfficer role. This will change once title user always has to order for external biz src user and not themselves
    // cannot be just OrderRole.BusinessSource because the API creates new contacts based off each biz src role & then removes all OrderRole.BusinessSource contacts
    // resulting in biz src not being able to view order since they're no longer on it
  } else if (hasOnlyServiceType(OrderServiceType.TitleDecision, services)) {
    return [OrderRole.TitleOfficer];

    // all other decision orders default to OrderRole.Lender
  } else if (isDecisionOrder(services)) {
    return [OrderRole.Lender];

    // Special IEO so special woowoo
  } else if (hasUserGroup(UserGroup.IndependentEscrowOfficer, user)) {
    return [OrderRole.IndependentEscrowOfficer];
  }

  // no other special default role cases, return empty array
  return [];
};

export enum EditableAdditionalContactTypes {
  MortgageBroker = OrderRole.MortgageBroker,
  BuyersAgent = OrderRole.BuyersAgent,
  SellersAgent = OrderRole.SellersAgent,
  Attorney = OrderRole.BuyersAttorney,
  Investor = OrderRole.SellersAttorney,
  IndependentEscrowOfficer = OrderRole.IndependentEscrowOfficer,
  IndependentTitleOfficer = OrderRole.IndependentTitleOfficer,
}

/** Contacts with these roles will show up in the bottom Additional Contacts section. */
export enum AdditionalContactTypes {
  MortgageBroker = OrderRole.MortgageBroker,
  Attorney = OrderRole.BuyersAttorney,
  Investor = OrderRole.SellersAttorney,
  IndependentEscrowOfficer = OrderRole.IndependentEscrowOfficer,
  IndependentTitleOfficer = OrderRole.IndependentTitleOfficer,
  Lender = OrderRole.Lender,
}

export const additionalContactsDropdown = enumToDictArray(EditableAdditionalContactTypes);

export const orderConsumerRoles = [OrderRole.Borrower, OrderRole.Buyer, OrderRole.Seller];
