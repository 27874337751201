export enum OrderStep {
  PropertyAddress = 0,
  BusinessSource,
  OrderType,
  OrderDetails,
  People,
  // Complete,
  OrderResult,
}

export enum NewOrderView {
  StepsOverview = 0,
  Forms,
}
