import AppConfig from "./appConfig";
import AuthService from "../utilities/authService";
import Axios, {
  AxiosRequestConfig,
  AxiosError,
  ResponseType,
  AxiosResponse,
  CancelTokenSource,
  AxiosProgressEvent,
} from "axios";
import ErrorHandler from "../components/controls/errorHandler";
import { hasKey } from "./typescriptHelpers";
import HttpStatusCode from "../dataTypes/statusCodes";
import Auth from "@aws-amplify/auth";

export interface OptionalRequestParams {
  // testingOrders is temporary solution for using the beta env for requests since your local DB needs lots of setup still
  testingOrders?: boolean;
  postWithURLQueryParams?: boolean;
  cancelToken?: any;
  allowAnonymous?: boolean;
  contentType?: string;
  responseType?: ResponseType;
  onDownloadProgress?: (progress: number) => void;
}

//-----------------------
// REQUEST STATUS INTERFACES AND HELPER FUNCTIONS

// testing this out - new approach to containing 3 essential data of a request.
//  - any redux action that calls the API could have an associated RequestStatus object
export interface RequestStatus {
  pending: boolean;
  successId: string | null;
  failureId: string | null;
}

export function newRequestStatus(): RequestStatus {
  return { pending: false, successId: "", failureId: "" };
}

// used best inside a react component's "componentDidUpdate" lifecycle method
// to compare the previous to current request status
export function requestOk(prevReqStatus: RequestStatus, currentReqStatus: RequestStatus): boolean | undefined {
  if (
    prevReqStatus.pending &&
    !currentReqStatus.pending &&
    prevReqStatus.successId !== currentReqStatus.successId &&
    currentReqStatus.successId
  )
    return true;

  if (
    prevReqStatus.pending &&
    !currentReqStatus.pending &&
    prevReqStatus.failureId !== currentReqStatus.failureId &&
    currentReqStatus.failureId
  )
    return false;
}

// newest approach to request status
export interface ReqStatus {
  pending: boolean;
  ok: boolean;
  downloadProgress: number;
  cancelTokenSource?: CancelTokenSource;
}

export function newReqStatus(): ReqStatus {
  return { pending: false, ok: false, downloadProgress: 0 };
}

// use this for whenever a new request is pending
export function newPendingReq(): ReqStatus {
  return { pending: true, ok: false, downloadProgress: 0 };
}

export function reqOk(): ReqStatus {
  return { pending: false, ok: true, downloadProgress: 0 };
}

export function reqFailed(): ReqStatus {
  return { pending: false, ok: false, downloadProgress: 0 };
}

export function reqComplete(prevReqStatus: ReqStatus, currentReqStatus: ReqStatus): boolean {
  return prevReqStatus.pending && !currentReqStatus.pending && currentReqStatus.ok ? true : false;
}

//------------------------
export function isAxiosError(payload: any): payload is AxiosError<any> {
  return typeof payload === "object" && payload.isAxiosError === true;
}

export function getAxiosErrorMessage(error: unknown): string {
  const defaultResponse: string = "Oops, something went wrong.";
  if (!isAxiosError(error)) return defaultResponse;
  if (error?.response?.data) {
    let message: string = "";

    // sometimes the message is directly on the data field
    if (typeof error.response.data === "string") message = error.response.data;
    // sometimes the message field is capitalized, other times not
    if (error.response.data?.message) message = error.response.data.message;
    if (error.response.data?.Message) message = error.response.data.Message;

    return message;
  } else {
    return defaultResponse;
  }
}

// when updating plz keep these sorted alphabetically :) JB
export enum ApiEndpoints {
  ApproveUser = "/api/Account/ApproveUser",
  CheckDocumentImagesCapability = "/api/PropertyData/CheckDocumentImagesCapability",
  CompanyAccountManagers = "/api/NewOrders/CompanyAccountManagers",
  CompanyLookupCodes = "/api/OrderOptions/GetAllLookupCodesForCompany",
  ContactUs = "/api/Public/ContactUs",
  DeleteDocumentImageRequest = "/api/PropertyData/DeleteDocumentImageRequest",
  DeleteFarm = "/api/Farms/DeleteFarm",
  DeleteFarmFilter = "/api/Farms/DeleteFarmFilter",
  DeleteOrderDocument = "/api/OrderDocumentSettings/DeleteOrderDocument",
  DeleteOrderDocumentTypeSetting = "/api/OrderDocumentSettings/DeleteOrderDocumentTypeSetting",
  DownloadTrailingDocument = "/api/Orders/DownloadTrailingDocument",
  FarmOverview = "/api/Farms/FarmOverview",
  FarmReport = "/api/Farms/FarmReport",
  GetActivePartners = "/api/AccountPartner/GetActivePartners",
  GetDecisionOrderData = "/api/Orders/GetDecisionOrderData",
  GetDocument = "/api/Orders/GetDocument",
  GetEffectivePermissionsDisplay = "/api/Permissions/GetEffectivePermissionsDisplay",
  GetEntityPermissions = "/api/Permissions/GetEntityPermissions",
  GetHyperLinkedDocuments = "/api/Documents/GetHyperLinkedDocuments",
  GetOrderDocumentList = "/api/OrderDocumentSettings/GetOrderDocumentList",
  GetPropertyDetails = "/api/PropertyData/PropertyDetail",
  GetPropertyDocumentImages = "/api/PropertyData/GetPropertyDocumentImages",
  GetPropertyTaxRolls = "/api/PropertyData/GetPropertyTaxRolls",
  GetUserProfile = "/api/Account/GetUserProfile",
  GetUserProfileById = "/api/Account/GetUserProfileById",
  LogError = "/api/Logs/LogError",
  MarkFarmUploadViewed = "/api/Farms/MarkFarmUploadViewed",
  MlsCoverage = "/api/PropertyData/MlsCoverage",
  ModifyDecisionOrderData = "/api/orders/ModifyDecisionOrderData",
  OrderDetail = "/api/Orders/OrderDetail",
  OrderHistory = "/api/OrderEvents/OrderHistory",
  PartnerBrandingByUrl = "/api/Partners/PartnerBrandingByUrl",
  PropertiesNearby = "/api/PropertyData/PropertiesNearby",
  PropertyProfileReport = "/api/PropertyData/PropertyProfileReport",
  PropertyProfileReportBase64 = "/api/PropertyData/PropertyProfileReportBase64",
  PropertySearchSuggestions = "/api/PropertyData/PropertySearchSuggestions",
  Realtor = "/api/PropertyData/RealtorDotComUrl",
  RelatedOfficers = "/api/NewOrders/RelatedOfficers",
  RequestDocumentImage = "/api/PropertyData/RequestDocumentImage",
  ResetPassword = "/api/Account/ResetPassword",
  SaveAndReturnPermission = "/api/Permissions/SaveAndReturnPermission",
  SaveEntityLookupCodes = "/api/OrderOptions/SaveEntityLookupCodes",
  SaveFarmFilter = "/api/Farms/SaveFarmFilter",
  SaveOrderDocument = "/api/OrderDocumentSettings/SaveOrderDocument",
  SaveOrderDocumentTypeSetting = "/api/OrderDocumentSettings/SaveOrderDocumentTypeSetting",
  SearchFarmGeoPoints = "/api/Farms/SearchFarmGeoPoints",
  SearchOfficersOrAccountExecs = "/api/Account/SearchOfficersOrAccountExecs",
  SearchOrderContact = "/api/NewOrders/SearchOrderContact",
  SearchTitleOfficers = "/api/NewOrders/CompanyTitleOfficers",
  SearchUsers = "/api/Account/SearchUsers",
  SetCurrentPartner = "/api/AccountPartner/SetCurrentPartner",
  SignMapUrl = "/api/PropertyData/SignMapUrl",
  SignUpUser = "/api/Account/SignUpUser",
  UpdateOrderStatus = "/api/Orders/UpdateOrderStatus",
  UpdatePartnerAssociation = "/api/Account/UpdatePartnerAssociation",
  UpdateTermsAcceptance = "/api/Account/UpdateTermsAcceptance",
  UpdateUserAssistants = "/api/Account/UpdateUserAssistants",
  UpdateUserBasicProfile = "/api/Account/UpdateUserBasicProfile",
  UpdateUserCompanySettings = "/api/Account/UpdateUserCompanySettings",
  UpdateUserStatus = "/api/Account/UpdateUserStatus",
  UserSettings = "/api/Account/UserSettings",
  ValidateUser = "/api/Account/ValidateUser",
  ZillowData = "/api/PropertyData/ZillowValuation",
}

export const secureAxios = Axios.create({ baseURL: AppConfig.ApiURL() });

secureAxios.interceptors.request.use(async (config) => {
  const tokenData = await Auth.currentSession();
  const jwt = tokenData.getAccessToken().getJwtToken();
  config.headers.authorization = `Bearer ${jwt}`;
  return config;
});

export const stringifyParams = (params: any) => "?" + new URLSearchParams(params).toString();

export const calculateDownloadProgress = (progressEvent: AxiosProgressEvent) => {
  const { total, loaded } = progressEvent;

  if (!total) return 0;

  return Math.floor((loaded / total) * 100);
};

/**
 * @deprecated
 * ApiService now deprecated
 * Use secureAxios instance for secure calls, or axios directly for insecure calls.
 * Use stringifyParams instead of BuildUrl
 */

export default class ApiService {
  static BuildURL(method: string, path: string, params: object, optionalRequestParams?: OptionalRequestParams) {
    // create the URL
    //let url: string;
    // only keep this optional testingOrders check as temporary solution in development since your local DB isn't setup for Orders
    //if (optionalRequestParams && optionalRequestParams.testingOrders) {
    //    url = 'https://api-beta.titlefy.com' + path;
    //} else {
    //    url = AppConfig.ApiURL() + path;
    //}

    let url = AppConfig.ApiURL() + path;

    // builds URL with query params
    if (method === "GET" && params) {
      //put them into a string (eg. "param1=val1&param2=val2")
      url +=
        "?" +
        Object.keys(params)
          .map((key) => {
            if (hasKey(params, key)) {
              return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
            }
          })
          .join("&");
    }

    if (optionalRequestParams && optionalRequestParams.postWithURLQueryParams) {
      if (method === "POST" && params && optionalRequestParams.postWithURLQueryParams === true) {
        //put them into a string (eg. "param1=val1&param2=val2")
        url +=
          "?" +
          Object.keys(params)
            .map((key) => {
              if (hasKey(params, key)) {
                return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
              }
            })
            .join("&");
      }
    }

    return url;
  }

  static async CallApi(
    method: string,
    path: string,
    params: object,
    completedCallback?: Function,
    failedCallback?: Function,
    optionalRequestParams?: OptionalRequestParams,
  ): Promise<AxiosResponse<any>> {
    const url = this.BuildURL(method, path, params, optionalRequestParams);

    try {
      let config: AxiosRequestConfig = {};

      // handle requests that don't require authorization, i.e. Sign Up related requests
      if (optionalRequestParams?.allowAnonymous !== true) {
        let tokenData = await AuthService.GetCurrentSession();

        const jwt = tokenData.getAccessToken().getJwtToken();

        config = { headers: { Authorization: `Bearer ${jwt}` } };

        if (optionalRequestParams?.cancelToken) config.cancelToken = optionalRequestParams.cancelToken;

        if (optionalRequestParams?.responseType) config.responseType = optionalRequestParams.responseType;

        // assign any optional contentType headers
        if (optionalRequestParams?.contentType) {
          config.headers = {
            ...config.headers,
            "Content-Type": optionalRequestParams.contentType,
          };
        }

        if (optionalRequestParams?.onDownloadProgress) {
          // our version of axios doesn't provide type definition for progressEvent
          config.onDownloadProgress = (progressEvent: any) => {
            // make sure request is ok
            if (progressEvent?.target?.status === HttpStatusCode.OK) {
              const total = progressEvent?.total;
              const current = progressEvent?.loaded;
              const percentCompleted = Math.floor((current / total) * 100);
              optionalRequestParams?.onDownloadProgress && optionalRequestParams?.onDownloadProgress(percentCompleted);
            }
          };
        }
      }

      if (method.toUpperCase() === "GET") {
        return this.AxiosGet(url, config, completedCallback, failedCallback);
      } else if (method.toUpperCase() === "POST") {
        return this.AxiosPost(url, config, params, completedCallback, failedCallback);
      } else {
        return Promise.reject("Incorrect method type");
      }
    } catch (error: any) {
      ErrorHandler.HandleError(error);

      throw error;
    }
  }

  static AxiosPost(
    url: string,
    config: AxiosRequestConfig,
    params: object,
    completedCallback?: Function,
    failedCallback?: Function,
  ): Promise<AxiosResponse<any>> {
    let pr = Axios.post(url, params, config);
    pr.then((response) => completedCallback?.(response.data)).catch((error) => {
      if (Axios.isCancel(error)) {
        console.log("CANCELLED REQUEST!");
      } else {
        failedCallback?.(error);
      }
    });
    return pr;
  }

  static AxiosGet(
    url: string,
    config: AxiosRequestConfig,
    completedCallback?: Function,
    failedCallback?: Function,
  ): Promise<AxiosResponse<any>> {
    let pr = Axios.get(url, config);
    pr.then((response) => completedCallback?.(response.data)).catch((error) => {
      if (Axios.isCancel(error)) {
        console.log("CANCELLED REQUEST!");
      } else {
        failedCallback?.(error);
      }
    });

    return pr;
  }
}
